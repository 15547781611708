import { OptionSchema } from '../../../../components/ui/utils'
import { ClientSchema } from '@core/zod/prismaZodSchema'
import { z } from 'zod'

export type Client = z.infer<typeof ClientSchema>

export const ClientFormSchema = z.object({
  client: OptionSchema(z.string(), ClientSchema),
})

export type ClientForm = z.infer<typeof ClientFormSchema>
