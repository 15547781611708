'use client'

import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Client, ClientForm, ClientFormSchema } from './types'
import { zodResolver } from '@hookform/resolvers/zod'
import { useClients } from './hooks'
import { ClientSchema } from '@core/zod/prismaZodSchema'
import { useRouter } from 'next/navigation'
import { clientToOption } from './utils'
import { z } from 'zod'
import { usePageVisibility } from '@/lib/hooks/usePageVisibility'

const CLIENT_STORAGE_KEY = 'client'

export const ClientContext = createContext<ReturnType<typeof useClient> | null>(null)

export function useClient() {
  // Is set by child components to indicate the client that is currently being viewed by user
  const viewedClient = useRef<Client | null>(null)

  const clientForm = useForm<ClientForm>({ resolver: zodResolver(ClientFormSchema) })
  const selectedClient = useWatch({ control: clientForm.control, name: 'client' })?.data
  const { clients, isLoadingClients } = useClients()
  const router = useRouter()

  const handleClientSelected = useCallback(
    (client: Client) => {
      sessionStorage.setItem(CLIENT_STORAGE_KEY, JSON.stringify(client))
    },
    [selectedClient],
  )

  const loadClientFromStorage = useCallback(() => {
    const clientInStorage = sessionStorage.getItem(CLIENT_STORAGE_KEY) ?? localStorage.getItem(CLIENT_STORAGE_KEY)

    if (!clientInStorage || viewedClient.current) {
      return
    }

    try {
      const client = ClientSchema.extend({ id: z.string() }).parse(JSON.parse(clientInStorage))

      clientForm.reset({ client: clientToOption(client) })
    } catch (error) {
      console.error('Error parsing client from local storage', { error, clientInStorage })
      sessionStorage.removeItem(CLIENT_STORAGE_KEY)
      return
    }
  }, [selectedClient, viewedClient])

  useEffect(() => {
    setTimeout(() => loadClientFromStorage(), 100) // wait for the viewedClient to be set by child components, potentially
  }, [])

  useEffect(
    function fillEmptyClientFromLoadedClients() {
      if (!clients?.length) return
      if (selectedClient) return

      if (!selectedClient && viewedClient.current) {
        clientForm.reset({ client: clientToOption(viewedClient.current) })
        return
      }

      clientForm.reset({ client: clientToOption(clients[0]) })
    },
    [selectedClient, clients, viewedClient.current],
  )

  useEffect(
    function redirectIfClientOutOfSync() {
      if (selectedClient && viewedClient.current && viewedClient.current.id !== selectedClient.id) {
        router.push('/investigation')
      }
    },
    [selectedClient, viewedClient],
  )

  const pageVisible = usePageVisibility()

  useEffect(() => {
    if (pageVisible && selectedClient) {
      localStorage.setItem(CLIENT_STORAGE_KEY, JSON.stringify(selectedClient))
    }
  }, [selectedClient, pageVisible])

  return {
    clientForm,
    clients,
    isLoadingClients,
    selectedClient,
    handleClientSelected,
    viewedClient,
  }
}

export function useClientContext() {
  const context = useContext(ClientContext)

  if (!context) {
    throw new Error('useClientContext must be used within a ClientProvider')
  }

  return context
}
