import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/home/runner/work/bps/bps/node_modules/.pnpm/next-auth@5.0.0-beta.25_next@15.0.2_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@19._2viuvq4gwvkw75mh4zzhacvndu/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/bps/bps/node_modules/.pnpm/next@15.0.2_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@19.0.0-rc-603e6108-20241029_shmlsksgbc27raajzjlet76yxi/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/bps/bps/web/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ClientProvider"] */ "/home/runner/work/bps/bps/web/app/lib/providers/ClientProvider/ClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider"] */ "/home/runner/work/bps/bps/web/app/lib/providers/QueryClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Authentication"] */ "/home/runner/work/bps/bps/web/app/lib/ui/Authentication/Authentication.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientSelector"] */ "/home/runner/work/bps/bps/web/app/lib/ui/WebHeader/ClientSelector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebNavigation"] */ "/home/runner/work/bps/bps/web/app/lib/ui/WebHeader/WebNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TrpcProvider"] */ "/home/runner/work/bps/bps/web/app/trpc/TrpcProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/work/bps/bps/web/components/ui/Toaster.tsx");
